/*global window */ 
(function() {

	'use strict';

	$(document).ready(function () {
		$('body').on('click', '.invia-contatto', function() {
      var validator = $('#form-contatto').validate({
        focusInvalid: false,
        onkeyup: false,
        onfocusout: false,
        errorElement: 'p',
        errorPlacement: function(error) {
          var tmpl = '<span class="glyphicon glyphicon-remove-circle" style="font-size: 70px; color:orange;"></span><br><br>' +
          '<h4>' + $(error).html() + '</h4><br>';

          attivaModal(tmpl, ['Chiudi'], ['']);

          $('div#errors').show();
          if ($('div#errors').html() === '') {
            error.appendTo('div#errors');
          }
        },
        rules: {
          nome: {
            required: true
          },
          cognome: {
            required: true
          },
          captcha: {
            required: true
          },
          telefono: {
            required: false
          },
          check_privacy: {
            required: true
          },
          email: {
            required: true,
            email: true
          }
        },
        messages: {
          nome: {
            required: setup.texts.testi['inserire-nome']
          },
          cognome: {
            required: setup.texts.testi['inserire-cognome']
          },
          check_privacy: {
            required: setup.texts.testi['check-privacy']
          },
          captcha: {
            required: setup.texts.testi['inserire-captcha']
          },
          telefono: {
            required: setup.texts.testi['inserire-telefono']
          },
          email: {
            required: setup.texts.testi['inserire-mail'],
            email: setup.texts.testi['mail-corretta']
          }
        },
        invalidHandler: function() {
          $('div#errors').html('');
        }
      });

      var arr = {};
      arr.email = $('#email').val();
      arr.telefono = $('#telefono').val();
      //arr.tipo = $('#tipo').val();
      arr.nome = $('#nome').val();
      arr.cognome = $('#cognome').val();
      arr.check_privacy = $('#check_privacy').is(':checked');
      arr.check_commercial = $('#check_commercial').is(':checked');
      arr.info = $('#info').val();
      //arr.captcha = $('#captcha').val();

      arr.idpagina = $('#idpagina').val();
      arr.pagina = $('#pagina').val();
      var jsonString = JSON.stringify(arr);


      if (validator.form()) {
        var tmpl = '<span class="glyphicon glyphicon-time" style="font-size: 70px; color:orange;"></span><br><br>' +
        '<h4>Stiamo elaborando la sua richiesta, attendere un attimo...</h4><br><br>';
        attivaModal(tmpl, null, null);

        var fd = new FormData();
        fd.append('action', 'invia-contatto-sito');
        fd.append('obj', jsonString);

        $.ajax({
          url: '/ajax/function.php',
          method: 'POST',
          contentType: false,
          processData: false,
          data: fd,
          success: function(ret) {
            var arr = ret.split('|');
            var tmpl = '';

            if (arr[0] === '1') {
              tmpl = '<span class="glyphicon glyphicon-send" style="font-size: 70px; color:orange;"></span><br><br><h4>' + arr[1] + '</h4><br><br>';
              $('#email').val('');
              $('#nome').val('');
              $('#cognome').val('');
              $('#check_privacy').val('');
              $('#telefono').val('');
              $('#info').val('');
              $('#captcha').val('');

              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'email-sent'
              });

              //ga('send', {
              //  hitType: 'event',
              //  eventCategory: 'Contact',
              //  eventAction: 'send-email',
              //  eventLabel: 'compilazione form'
              //});

            } else {
              tmpl = '<span class="glyphicon glyphicon-remove-sign" style="font-size: 70px; color:orange;"></span><br><br><h4>' + arr[1] + '</h4><br><br>';
            }
            attivaModal(tmpl, ['Chiudi'], [''], null);
          }
        });
        return false;
      }
      return false;
    });


    var attivaModal = function(message, buttons, idButtons, callBack) {
      $('.modal-body p').hide();
      $('.modal-body p').html(message);
      $('.modal-body p').show();

      if (!$('.modal').hasClass('in')) {
        $('.modal').modal('show');
      }

      $('.modal-footer').html('');

      if (typeof buttons !== 'undefined' && buttons !== null) {
        if (buttons.length > 0) {
          $.each(buttons, function(index, value) {
            if (idButtons[index] === '') {
              $('.modal-footer').append('<button type="button" class="btn btn-default" data-dismiss="modal">' + value + '</button>');
            } else {
              $('.modal-footer').append('<button type="button" class="btn btn-default" id="modal-action-' + idButtons[index] + '">' + value + '</button>');
            }
          });
        }
      }
      if (typeof callBack === 'function') {
        callBack();
      }
    };

	});
}());
